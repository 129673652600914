import React, { useRef, useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { Box, Typography, Divider, Button } from "@mui/material";
import foglar from "../../assets/foglar.webp";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import DocuSignModal from "./DocuSignModal";
import base_url from "../../api/bootapi";
import axios from "axios";
import { useSelector } from "react-redux";

const AgreementContent = React.forwardRef((props, ref) => {
  const {
    onPdfGenerated,

    setEnvelopeId,
    envolpeId,
    setbackdropopen,
    onRequestClose,
    signUrl,
    setSignUrl,
    setIsDocusignModalOpen,
    setIsFetching,
    setDocumentFetched,
    agreementDetails,
  } = props;
  const {
    date,
    clientName,
    clientAddress,
    passportNumber,
    clientEmail,
    bookingNumber,
    clientBirthDate,
    familyMembers,
    flightDetails,
  } = agreementDetails;

  const contentRef = useRef();

  const [htmlString, setHtmlString] = useState("");

  const [pdfDocument, setPdfDocument] = useState(null);

  const [pdfGenerated, setPdfGenerated] = useState(false);

  const handleSignature = async () => {
    setbackdropopen(true);
    const formData = new FormData();

    const formObject = {
      signer_email: clientEmail,
      signer_name: clientName,
      // return_url: "http://localhost:3000/#/thank-you",
      return_url: "https://linsoatours.com/#/thank-you",
      // return_url: "https://linsoatours.odisoft.in/#/thank-you",
      // page_number: 1,
      // x_position: 400,
      // y_position: 180,
      html_string: htmlString,
    };
    const jsonFormObject = JSON.stringify(formObject);
    formData.append("data", jsonFormObject);
    // formData.append("document", pdfDocument);

    // const sign_url = `${base_url.api1}/tourapp/create_envelope_for_sign`;
    const sign_url = `${base_url.api1}/tourapp/wesign_doc`;

    try {
      const response = await axios.post(sign_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data && response.data.envelope) {
        console.log("Envelope URL:", response.data.envelope.envelope_url);

        setSignUrl(response.data.envelope.envelope_url);
        setEnvelopeId(response.data.envelope.envelope_id);
        onRequestClose();
        setIsDocusignModalOpen(true);
        // const newTab = window.open(
        //   response.data.envelope.envelope_url,
        //   "_blank"
        // );
        // if (newTab) {
        //   newTab.focus();
        // } else {
        //   console.error("Failed to open the URL in a new tab.");
        // }
      }
      setbackdropopen(false);

      // setIsModalOpen(true);
    } catch (error) {
      console.error("Error creating envelope:", error);
      setbackdropopen(false);
    }
  };
  const generatePDF = () => {
    setbackdropopen(true);
    // const htmlString = ReactDOMServer.renderToString(
    //   <AgreementContent {...props} />
    // );
    const content = contentRef.current;
    if (content) {
      const generatedHtml = content.innerHTML;
      setPdfGenerated(true);
      setHtmlString(generatedHtml);
      console.log("generated html", generatedHtml);
    }

    setbackdropopen(false);
  };

  useEffect(() => {
    const image = new Image();
    image.src = foglar;
    image.onload = () => {
      generatePDF();
    };
  }, []);

  const FlightDetailsList = () => {
    if (flightDetails.length > 1) {
      return (
        <ol style={{ paddingLeft: "20px" }}>
          <li>
            <ul style={{ listStyleType: "lower-roman", paddingLeft: "20px" }}>
              {flightDetails.map((flight, index) => (
                <>
                  <li key={index}>
                    The Client, on its own behalf [and on behalf of the persons
                    it is representing], declares that it has claims against{" "}
                    {flight.airline} for compensation on the basis of Regulation
                    (EC) No 261/2004 for a delayed/canceled flight{" "}
                    {flight.flight_number} on {flight.date_of_travel}, increased
                    by potential claims for damages suffered and interest for
                    late payment (the <strong>“Claim”</strong>).
                  </li>
                  {index < flightDetails.length - 1 && <br />}
                </>
              ))}
            </ul>
          </li>
        </ol>
      );
    } else if (flightDetails.length === 1) {
      const flight = flightDetails[0];
      return (
        <p>
          1. The Client, on its own behalf [and on behalf of the persons it is
          representing], declares that it has claims against {flight.airline}{" "}
          for compensation on the basis of Regulation (EC) No 261/2004 for a
          delayed/canceled flight {flight.flight_number} on{" "}
          {flight.date_of_travel}, increased by potential claims for damages
          suffered and interest for late payment (the <strong>“Claim”</strong>).
        </p>
      );
    } else {
      return <p>No flight details available.</p>;
    }
  };

  return (
    <div
      ref={ref}
      style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
    >
      <div
        style={{
          position: "relative",
          padding: "16px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
          breakInside: "avoid",
        }}
        ref={contentRef}
      >
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://api.linsoatours.com/Images/other_document/foglar.webp"
            alt="Logo"
            style={{ width: "150px", height: "auto", marginRight: "20px" }}
          />
        </div> */}

        <h1 style={{ textAlign: "center", marginBottom: "16px" }}>
          Assignment Agreement
        </h1>

        <h2 style={{ marginBottom: "16px" }}>
          Concluded on {date} by and between:
        </h2>

        <p>
          {clientName}, residing at {clientAddress}, passport number{" "}
          {passportNumber}, booking number {bookingNumber},
        </p>
        <p>
          hereinafter referred to as the <strong>“Client”</strong>,
        </p>

        {familyMembers.length > 0 && (
          <>
            <p>Acting at the same time on behalf of:</p>
            <ol>
              {familyMembers.map((member, index) => (
                <li key={index}>
                  <p>
                    {member.first_name} {member.last_name}, born{" "}
                    {member.date_of_birth}, passport number{" "}
                    {member.passport_number}, ticket number{" "}
                    {member.ticket_number}
                  </p>
                </li>
              ))}
            </ol>
          </>
        )}

        <p>and</p>

        <p>
          Linsoa Tours and Leisure Travel Inc, 11 Main Street Monsey 10952 New
          York, United States,
        </p>
        <p>
          hereinafter referred to as the <strong>“Provider”</strong>.
        </p>

        <hr style={{ margin: "20px 0" }} />

        <section style={{ marginBottom: "24px" }}>
          <h2 style={{ textAlign: "center", marginBottom: "16px" }}>
            <strong>§1</strong>
          </h2>
          <p>
            <FlightDetailsList />
          </p>
          <p>
            2. The Client declares that he remains fully entitled to the Claim
            and that it has not been assigned to any third parties nor have any
            third parties been engaged in trying to assert the Claim.
          </p>
          <p>
            3. The Client hereby assigns its Claim and all rights arising
            therein to the Provider, allowing the Provider to assert the Claim
            in its own name and to conduct all legal action, negotiations and
            make any settlements with the airline at its discretion. The
            Provider is further entitled to collect any and all compensation
            received from the airline and is later obliged to transfer it to the
            Client after offsetting the agreed fee.
          </p>
          <p>
            4. The Provider acts with due diligence and strives to acquire
            compensation on behalf of the Client. The Provider does not,
            however, guarantee that his services will be successful in acquiring
            the compensation and may therefore not be held liable solely based
            on the fact that the services did not result in receipt of
            compensation. The Client is aware that it may take many months (even
            years) before any compensation is potentially acquired.
          </p>
        </section>

        <hr style={{ margin: "20px 0" }} />

        <section style={{ marginBottom: "24px" }}>
          <h2 style={{ textAlign: "center", marginBottom: "16px" }}>
            <strong>§2</strong>
          </h2>
          <p>
            1. This Assignment Agreement is subject to Polish law and the
            jurisdiction of Polish courts.
          </p>
          <p>2. The Assignment Agreement was concluded in two copies.</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ textAlign: "center" }}>
              <p style={{ marginBottom: "10px" }}>
                <div
                  style={{ width: "300px", height: "70px" }}
                  className="signatureContainer"
                ></div>
                _____________________________________
              </p>
              <p>
                <strong>Provider signature</strong>
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <p style={{ marginBottom: "10px" }}>
                <div style={{ width: "300px", height: "70px" }}>
                  {pdfGenerated && (
                    <button
                      style={{
                        maxHeight: "40px",
                        top: "39px",
                        left: "10px",
                        position: "relative",
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        padding: "8px 16px",
                        cursor: "pointer",
                      }}
                      onClick={handleSignature}
                    >
                      Sign Document
                    </button>
                  )}
                </div>
                _____________________________________
              </p>
              <p>
                <strong>Client signature</strong>
              </p>
            </div>
          </div>
        </section>

        {/* <hr style={{ margin: "20px 0" }} /> */}
        {/* 
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}
        >
          <div style={{ flex: "1" }}>
            <p>Foglar Law Firm (NIP: 7343586718)</p>
          </div>
          <div style={{ flex: "1", textAlign: "center" }}>
            <p>www.foglar.pl</p>
          </div>
          <div style={{ flex: "1", textAlign: "right" }}>
            <p>claims@foglar.pl</p>
          </div>
        </div> */}
      </div>
    </div>
  );
});

export default AgreementContent;
