import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DocuSignModal = ({
  isOpen,
  onRequestClose,
  signUrl,
  handleIframeClose,
}) => {
  const [backdropopen, setbackdropopen] = useState(false);

  const handleLoad = () => {
    setbackdropopen(false);
  };

  const handleClose = () => {
    handleIframeClose();
    onRequestClose();
  };

  // useEffect(() => {
  //   if (signUrl) {
  //     setbackdropopen(true);
  //   }
  // }, []);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: "3000" }} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "125%",
            overflow: "auto",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
          overlay: {
            zIndex: 2000, // Ensure overlay is above the previous modal
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        {/* <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            style={{ marginBottom: "10px" }}
          >
            Close
          </Button>
        </div> */}
        {signUrl ? (
          <iframe
            src={signUrl}
            title="DocuSign"
            width="100%"
            height="600px"
            onLoad={handleLoad}
            style={{ border: "none" }}
          ></iframe>
        ) : (
          <p>Loading...</p>
        )}
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            style={{ marginBottom: "10px" }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DocuSignModal;
